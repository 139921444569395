import React, { FC } from 'react';
import loadable from '@loadable/component';

import { ContactSectionVariant } from 'components/molecules/ContactSection';
import { FormSectionVariant } from 'components/molecules/FormSection';

import {
  StyledFooter,
  StyledFooterBottom,
  StyledFooterMiddle,
  StyledFooterMiddleContainer,
  StyledFooterTop,
  StyledFooterTopContainer,
} from './Footer.styles';

import { FooterProps } from './models.d';

const ContactSection = loadable(() => import('components/molecules/ContactSection'));
const FormSection = loadable(() => import('components/molecules/FormSection'));
const BottomFooter = loadable(() => import('components/organisms/Footer/BottomFooter'));
const CompanyFooter = loadable(() => import('components/organisms/Footer/CompanyFooter'));
const LinksFooter = loadable(() => import('components/organisms/Footer/LinksFooter'));

const Footer: FC<FooterProps> = ({
  formSection,
  contactSection,
  companySection,
  redirectSection,
  footnote,
  isFooterTop = true,
}) => (
  <StyledFooter data-testid="footer">
    {isFooterTop ? (
      <StyledFooterTopContainer contentWidth={1280}>
        <StyledFooterTop>
          <FormSection {...formSection} formVariant={FormSectionVariant.FOOTER} />
          <ContactSection {...contactSection} contactVariant={ContactSectionVariant.FOOTER} />
        </StyledFooterTop>
      </StyledFooterTopContainer>
    ) : null}
    <StyledFooterMiddleContainer contentWidth={1280}>
      <StyledFooterMiddle>
        <CompanyFooter {...companySection} />
        <LinksFooter {...redirectSection} />
      </StyledFooterMiddle>
    </StyledFooterMiddleContainer>
    <StyledFooterBottom contentWidth={1280}>
      <BottomFooter {...footnote} />
    </StyledFooterBottom>
  </StyledFooter>
);
export default Footer;
