import styled from 'styled-components';
import { breakpoint } from 'styles';

import Container from 'components/molecules/Container';

export const StyledFooterContainer = styled.div`
  display: flex;
  padding: 0 var(--gap-xxs);

  ${breakpoint.xl} {
    padding: 0;
  }
`;

export const StyledFooter = styled.footer`
  position: relative;
  color: var(--c-white);
  background: var(--c-gray-900);
`;

export const StyledFooterTopContainer = styled(Container)`
  ${breakpoint.lg} {
    border-bottom: 1px solid var(--c-gray-700);
  }
`;

export const StyledFooterTop = styled(StyledFooterContainer)`
  display: grid;
  min-height: 400px;
  padding: 0;

  ${breakpoint.lg} {
    grid-template-columns: 44% 56%;
  }

  ${breakpoint.xl} {
    grid-template-columns: 48.5% 51.5%;
  }
`;

export const StyledFooterMiddleContainer = styled(Container)`
  ${breakpoint.md} {
    &::after {
      width: 100%;
      height: 1px;
      content: '';
      background-color: var(--c-gray-700);
    }
  }

  ${breakpoint.lg} {
    border-bottom: 1px solid var(--c-gray-700);

    &::after {
      display: none;
    }
  }
`;

export const StyledFooterMiddle = styled(StyledFooterContainer)`
  display: grid;
  min-height: 200px;
  padding: 0;

  ${breakpoint.md} {
    grid-template-columns: 194px 1fr;
  }

  ${breakpoint.xl} {
    grid-template-columns: 1fr 2fr;
  }
`;

export const StyledFooterBottom = styled(Container)`
  padding-top: var(--gap-md);
  padding-bottom: var(--gap-md);
`;
